import React, { useState } from 'react';
import { PDFDocument } from 'pdf-lib';

export default function DownloadProducts({ products }) {
    const [producttodownload, setProductToDownload] = useState();

    const downloadproductdata = async () => {
        const pdfDoc = await PDFDocument.create();

        // Calculate the number of pages needed
        const totalPages = Math.ceil(products.length / 12);

        for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
            const page = pdfDoc.addPage();

            const { width, height } = page.getSize();
            const fontSize = 10; // Decreased font size

            // Set the title
            // page.drawText(`Product List - Page ${currentPage}`, { x: 50, y: height - 50, fontSize: 18 });

            // Calculate the grid layout
            const columns = 3;
            const rows = 4;
            const gridWidth = width - 100;
            const gridHeight = height - 100;
            const cellWidth = gridWidth / columns - 12;
            const cellHeight = gridHeight / rows - 25;
            const padding = 25;

            // Iterate over each product in the range and add details to the PDF
            let xPosition = 50;
            let yPosition = height - 80;

            for (let i = (currentPage - 1) * 12; i < currentPage * 12 && i < products.length; i++) {
                const product = products[i];

                console.log("Creating");
                const { name, price, image } = product;

                // Add image
                const imageUrl = `.${image[0]}`; // Assuming image paths are relative
                const imageBytes = await fetch(imageUrl).then((res) => res.arrayBuffer());

                // Convert the image to PNG format
                const pngImageBytes = await convertToPng(imageBytes);
                const productImage = await pdfDoc.embedPng(pngImageBytes);

                // Draw image in the grid cell with decreased size
                const imageSize = 40; // Decreased image size
                page.drawImage(productImage, { x: xPosition, y: yPosition - cellHeight, width: cellWidth, height: cellHeight });

                // Add title and price below the image with padding
                page.drawText(name, { x: xPosition, y: yPosition - cellHeight + imageSize - 50, size:fontSize });
                page.drawText(`Price: $${price.toFixed(2)}`, { x: xPosition, y: yPosition - cellHeight + imageSize - 60, size:fontSize });

                // Move to the next grid cell position with padding
                xPosition += cellWidth + padding;

                // Move to the next row if the last column is reached
                if (i % columns === columns - 1) {
                    xPosition = 50;
                    yPosition -= cellHeight + padding;
                }
            }
        }

        // Save the PDF to a file
        const pdfBytes = await pdfDoc.save();

        // Create a blob from the PDF bytes and create a download link
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'product-list.pdf';
        link.click();
    };

    // Function to convert image to PNG format
    const convertToPng = async (imageBytes) => {
        const imageBlob = new Blob([imageBytes], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(imageBlob);
        const img = document.createElement('img');
        img.src = imageUrl;

        return new Promise((resolve) => {
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, img.width, img.height);
                canvas.toBlob((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(new Uint8Array(reader.result));
                    reader.readAsArrayBuffer(blob);
                }, 'image/png');
            };
        });
    };

    return (
        <div>
            <button className='btn btn-hover' onClick={() => downloadproductdata()}>
                Download Data
            </button>
        </div>
    );
}