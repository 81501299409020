import React, { Fragment, useEffect, useState } from "react";
import { Router, useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import cogoToast from 'cogo-toast';
import axios from "axios";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import Cookies from "js-cookie";
// import ReactGA from "react-ga";

const ForgetPassword = () => {
    let { pathname } = useLocation();
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState(0);
    const [tempOtp, setTempOtp] = useState(null);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [otpMatch, setOtpMatch] = useState(false);
    const [error, setError] = useState(false);

    const [serverURI, setServerURI] = useState("https://pavitra-server.vercel.app");

    const getOtp = (e) => {

        e.preventDefault();


        axios.post(`${serverURI}/api/auth/send-otp`, {
            email: email,
        })
            .then((response) => {
                if (response.data.error) {
                    // setError1(true);
                    cogoToast.error("Error while logging in");
                    return;
                }
                setOtp(response.data.OTP);
            })
            .catch((error) => {
                console.log(error);
                cogoToast.error("Error while logging in");
                setError(true);
            });
    }

    const changePassword = () => {

        if (password !== confirmPassword) {
            // alert("Password and Confirm Password should be same")
            cogoToast.error("Password and Confirm Password should be same")
            return
        }

        let data = JSON.stringify({
            "new_password": password,
        });

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${serverURI}/api/auth/change-password`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get("token")}`
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                cogoToast.success("Password changed successfully")
                document.location.href = process.env.PUBLIC_URL + "/login-register";
            })
            .catch((error) => {
                console.log(error);
                cogoToast.error("Error in changing password")
            });
    }


    const inputComponent = () => {
        return (
            <input
                type="text"
                name="otp"
                placeholder="Enter OTP"
                value={tempOtp}
                onChange={
                    (e) => {
                        setTempOtp(e.target.value);
                    }
                }
            />
        );
    }

    useEffect(() => {
        // const sendPageView = () => {
        //     ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        // };
        // sendPageView()
        if (Cookies.get('token') !== undefined) {
            cogoToast.success("Already Logged in");
            document.location.href = process.env.PUBLIC_URL + "/";
        }
    }, []);

    return (
        <Fragment>
            <SEO
                titleTemplate="Qualityzon Forget Password"
                description="Forget Password of Qualityzon"
            />
            <LayoutOne
                headerContainerClass="container-fluid"
                headerPaddingClass="header-padding-2"
                headerTop="hidden">
                { }
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Forget Password", path: process.env.PUBLIC_URL + pathname }
                    ]}
                />
                <div className="login-register-area pt-100 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-12 ms-auto me-auto">
                                <div className="login-register-wrapper">
                                    { }
                                    <Tab.Container defaultActiveKey="login">
                                        <Nav variant="pills" className="login-register-tab-list">
                                            <Nav.Item>
                                                <Nav.Link eventKey="login">
                                                    <h4>Forget Password</h4>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="login">
                                                <div className="login-form-container">
                                                    <div className="login-register-form">
                                                        <div>
                                                            {
                                                                // do not show if otp is received from server
                                                                otp === 0 & otpMatch === false &&
                                                                <input
                                                                    type="email"
                                                                    name="email"
                                                                    placeholder="Enter Email"
                                                                    value={email}
                                                                    onChange={
                                                                        (e) => {
                                                                            setEmail(e.target.value);
                                                                        }
                                                                    }
                                                                />
                                                            }
                                                            {
                                                                // show if otp is received from server and otp is not matched
                                                                otp !== 0 && otpMatch === false &&
                                                                inputComponent()
                                                            }
                                                            {
                                                                otpMatch === true &&
                                                                <>
                                                                    <input
                                                                        type="password"
                                                                        name="password"
                                                                        placeholder="Enter Password"
                                                                        value={password}
                                                                        onChange={
                                                                            (e) => {
                                                                                setPassword(e.target.value);
                                                                            }
                                                                        }
                                                                    />
                                                                    <input
                                                                        type="password"
                                                                        name="confirmPassword"
                                                                        placeholder="Confirm Password"
                                                                        value={confirmPassword}
                                                                        onChange={
                                                                            (e) => {
                                                                                setConfirmPassword(e.target.value);
                                                                            }
                                                                        }
                                                                    />
                                                                </>
                                                            }
                                                            <div className="button-box">
                                                                {
                                                                    otpMatch === false &&
                                                                    <button type="button"
                                                                        onClick={
                                                                            (e) => {
                                                                                if (otp === 0) {
                                                                                    getOtp(e);
                                                                                } else if (otp !== 0) {
                                                                                    if (otp === parseInt(tempOtp)) {
                                                                                        setOtpMatch(true);
                                                                                    } else {
                                                                                        cogoToast.error("Invalid OTP");
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {
                                                                                // show if otp is received from server
                                                                                otp === 0 &&
                                                                                "Get OTP"
                                                                            }
                                                                            {
                                                                                // show if otp is received from server
                                                                                otp !== 0 &&
                                                                                "Verify OTP"
                                                                            }
                                                                        </span>
                                                                    </button>
                                                                }
                                                            </div>
                                                            {
                                                                otpMatch === true &&
                                                                <div className="button-box">
                                                                    <button type="button"
                                                                        onClick={changePassword}
                                                                    >
                                                                        <span>Change Password</span>
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutOne>
        </Fragment >
    );
};

export default ForgetPassword;