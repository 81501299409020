import React from 'react'

const MobileSearch = () => {

  const [search, setSearch] = React.useState('')

  return (
    <div className="offcanvas-mobile-search-area">
      {/* <form action="#">
        <input type="search" placeholder="Search ..." />
        <button type="submit">
          <i className="fa fa-search" />
        </button>
      </form> */}
      <form onSubmit={(e) => {
        e.preventDefault()
        window.location.href = `/search/${search}`
      }}>
        <input type="text" placeholder="Search here..." value={search} onChange={(e) => setSearch(e.target.value)} id="search" name="search" />
        <button className="submit">
          <i className="fa fa-search" />
        </button>
      </form>
    </div>
  );
};

export default MobileSearch;
