import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { setActiveSort } from "../../helpers/product";

const ShopCategories = ({ categories, getSortParams }) => {

  const [isDropdown, setIsDropdown] = useState(false);

  const displaySize = document.documentElement.clientWidth;


  return (
    <div className="sidebar-widget">
      <div className="pro-sidebar-title"
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        Categories
        <button
          onClick={() => setIsDropdown(!isDropdown)}
          className={isDropdown ? "pro-sidebar-title-active" : ""}
          style={{
            border: "none",
            backgroundColor: "transparent",
            fontSize: "20px",
            display: displaySize > 991 ? "none" : "block",
          }}
        >
          {isDropdown ? "-" : "+"}
        </button>
      </div>
      <div className="sidebar-widget-list mt-30"
        style={{
          // display: isDropdown ? "block" : "none",
          display: isDropdown ? "block" : displaySize > 991 ? "block" : "none",
        }}
      >
        {categories ? (
          <ul>
            <li>
              <div className="sidebar-widget-list-left">
                <button
                  onClick={e => {
                    getSortParams("category", "");
                    setActiveSort(e);
                  }}
                >
                  <span className="checkmark" /> All Categories
                </button>
              </div>
            </li>
            {categories.map((category, key) => {
              return (
                <li key={key}>
                  <div className="sidebar-widget-list-left">
                    <button
                      onClick={e => {
                        getSortParams("category", category);
                        setActiveSort(e);
                      }}
                    >
                      {" "}
                      <span className="checkmark" /> {category}{" "}
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          "No categories found"
        )}
      </div>
    </div >
  );
};

ShopCategories.propTypes = {
  categories: PropTypes.array,
  getSortParams: PropTypes.func
};

export default ShopCategories;
