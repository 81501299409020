import React from 'react'
import axios from 'axios'
import { useState, useEffect,Fragment } from 'react'
import { useParams, useLocation } from "react-router-dom";
import EcommerceOrderProduct from './EcommerceOrderProduct'
import Cookies from 'js-cookie'
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
// import ReactGA from "react-ga";

const OrderDetail = () => {

    let { pathname } = useLocation();
    let { id } = useParams();
    const [orderDetail, setOrderDetail] = useState();
    const [loading, setLoading] = useState(true);

    const isLoggesIn = () => {
        if (Cookies.get('token')) {
            return true
        }
        return false
    }


    const reloadProduct = () => {

        setLoading(true);
        console.log("Order Detail");
        console.log(id);
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://pavitra-server.vercel.app/api/order/${id}`,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                console.log("Order Detail");
                console.log(response.data.order);
                setOrderDetail(response.data.order);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }

    useEffect(() => {
        // if not logged in redirect to login page
        // const sendPageView = () => {
        //     ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        // };
        // sendPageView()
        if (!isLoggesIn()) {
            document.location.href = process.env.PUBLIC_URL + "/login-register"
        }
        reloadProduct();
    }, []);

    return (
        <LayoutOne headerTop="hidden">
            {/* breadcrumb */}
            <Breadcrumb
                pages={[
                    { label: "Home", path: process.env.PUBLIC_URL + "/" },
                    { label: "Order Detail", path: process.env.PUBLIC_URL + "/order" }
                ]}
            />
            <Fragment>
                <div>
                    <div className='container'>
                        <div className='row'>
                            <h1 style={{
                                fontSize: '1.3rem',
                                fontWeight: '500'
                            }} className="fw-bold fs-18 mb-4">
                                Product Details
                            </h1>
                            <div className="table-content table-responsive cart-table-content">
                                <table>
                                    <thead className="table-light text-muted">
                                        <tr>
                                            <th scope="col">Product Details</th>
                                            <th scope="col">Item Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">
                                                SKU
                                            </th>
                                            <th scope="col">
                                                Category
                                            </th>
                                            <th scope="col" className="text-end">
                                                Total Amount
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderDetail && orderDetail.products.map((product, key) => (
                                            <EcommerceOrderProduct product={product} key={key} />
                                        ))}
                                        <tr className="border-top border-top-dashed">
                                            <td colSpan="4"></td>
                                            <td colSpan="2" className="fw-medium p-0">
                                                <table className="table table-borderless mb-0">
                                                    <tbody>
                                                        <tr className="">
                                                            <th scope="row">Total :</th>
                                                            <th className="text-end">{orderDetail?.total_price}</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12">
                                <div className="mt-5">
                                    <h1 style={{
                                        fontSize: '1.3rem',
                                        fontWeight: '500'
                                    }} className="fw-bold fs-18 mb-4">
                                        Shipping Details
                                    </h1>
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Name:</th>
                                                <td>{orderDetail?.first_name} {orderDetail?.last_name}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Phone:</th>
                                                <td>{orderDetail?.phone_number}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Address:</th>
                                                <td>{orderDetail?.street_address},{orderDetail?.apartment}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">City:</th>
                                                <td>{orderDetail?.city}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">State:</th>
                                                <td>{orderDetail?.state}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Postal Code:</th>
                                                <td>{orderDetail?.postal_code}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Country:</th>
                                                <td>{orderDetail?.country}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mt-5">
                                    <h1 style={{
                                        fontSize: '1.3rem',
                                        fontWeight: '500'
                                    }} className="fw-bold fs-18 mb-4">
                                        Shipping Method
                                    </h1>
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Shipping Company:</th>
                                                <td>{orderDetail?.shipping_company}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Shipping Tracking Number:</th>
                                                <td>{orderDetail?.shipping_tracking_number}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Shipping Tracking url:</th>
                                                <td>
                                                    <a href={orderDetail?.shipping_tracking_url} target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {orderDetail?.shipping_tracking_url}
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mt-5">
                                    <h1 style={{
                                        fontSize: '1.3rem',
                                        fontWeight: '500'
                                    }} className="fw-bold fs-18 mb-4">
                                        Payment Details
                                    </h1>
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Payment Method:</th>
                                                <td>{orderDetail?.payment_method}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Payment Status:</th>
                                                <td>{orderDetail?.payment_status}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mt-5">
                                    <h1 style={{
                                        fontSize: '1.3rem',
                                        fontWeight: '500'
                                    }} className="fw-bold fs-18 mb-4">
                                        Order Details
                                    </h1>
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Order ID:</th>
                                                <td>{orderDetail?._id}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Order Date:</th>
                                                <td>{new Date(orderDetail?.date).toLocaleDateString()}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Order Status:</th>
                                                <td>{orderDetail?.order_status}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Order Type:</th>
                                                <td>{orderDetail?.type}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mt-5">
                                    <h1 style={{
                                        fontSize: '1.3rem',
                                        fontWeight: '500'
                                    }} className="fw-bold fs-18 mb-4">
                                        Order Notes
                                    </h1>
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td>{orderDetail?.additional_info}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        </LayoutOne>
    )
}

export default OrderDetail
