import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import { setProducts } from "./store/slices/product-slice"
// import products from "./data/products.json";
import 'animate.css';
import 'swiper/swiper-bundle.min.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import "./i18n";
import axios from "axios";
// import ReactGA from 'react-ga4';

// ReactGA.initialize("G-DW1Z8J1PE3"); // Replace with your actual ID



axios.get("https://pavitra-server.vercel.app/api/product/all-enabled").then((res) => {
  store.dispatch(setProducts(res.data?.products?.products));
  console.log(res.data.product.products.length)
});
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <PersistProvider>
      <App />
    </PersistProvider>
  </Provider>
);

