import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DonwloadData from "./pages/other/DownloadData";
import OrderDetail from "./pages/other/OrderDetail";
import { Analytics } from "@vercel/analytics/react"
import ForgetPassword from "./pages/other/Forget-Password";
const HomeFashionEight = lazy(() => import("./pages/home/HomeFashionEight"));
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ShopGridSearch = lazy(() => import("./pages/shop/ShopGridSearch"));
const Product = lazy(() => import("./pages/shop-product/Product"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const OrderList = lazy(() => import("./pages/other/OrderList"))
const NotFound = lazy(() => import("./pages/other/NotFound"));
const PageDetail = lazy(() => import("./pages/other/PageDetail"));

const App = () => {

  return (
    <Router>
      <Analytics />
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Routes>
            <Route
              path={process.env.PUBLIC_URL + "/"}
              element={<HomeFashionEight />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/shop"}
              element={<ShopGridStandard />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/search/:text"}
              element={<ShopGridSearch />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/product/:id"}
              element={<Product />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/forgot-password"}
              element={<ForgetPassword />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/contact"}
              element={<Contact />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/my-account"}
              element={<MyAccount />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/login-register"}
              element={<LoginRegister />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/cart"}
              element={<Cart />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/wishlist"}
              element={<Wishlist />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/compare"}
              element={<Compare />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/download-data"}
              element={<DonwloadData />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/checkout"}
              element={<Checkout />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/orders"}
              element={<OrderList />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/order/:id"}
              element={<OrderDetail />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/page/:title"}
              element={<PageDetail />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};
export default App;