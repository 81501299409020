import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";

const Logo = ({ imageUrl, logoClass, iconWhiteClass }) => {

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  return (
    <div className={clsx(logoClass)}>
      {/* <div className={clsx("header-right-wrap", iconWhiteClass)} >

        <div className="same-style mobile-off-canvas d-block d-lg-none">
          <button
            className="mobile-aside-button"
            onClick={() => triggerMobileMenu()}
          >
            <i className="pe-7s-menu" />
          </button>
        </div>
      </div> */}
      <Link to={process.env.PUBLIC_URL + "/"}>
        <img alt="" src={process.env.PUBLIC_URL + imageUrl}
          className="my-fenil-logo"
        // style={{
        //   width: "230px",
        //   padding: "10px",
        // }}
        />
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string
};

export default Logo;
