import cogoToast from "cogo-toast";
import useScrollTop from "../../hooks/use-scroll-top";
import Cookies from "js-cookie";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";


const ScrollToTop = () => {
    const { stick, onClickHandler } = useScrollTop();
    const [modalStatus, isOpen] = useState(false);
    const [landinModalStatus, isLandingOpen] = useState(false);

    useEffect(() => {
        if (Cookies.get("isFirstTime") === undefined || Cookies.get("isFirstTime") === "true") {
            isLandingOpen(true);
        }
    }, []);

    const switchToWholeSale = () => {
        // Cookies.set("isWholesale", "true", { expires: 7 });
        // cogoToast.success(
        //     `You are now in the ${Cookies.get("isWholesale") ? "wholesale" : "retail"} mode`,
        //     {
        //         position: "top-right",
        //     }
        // );
        // if it is in the wholesale mode then switch to retail mode and vice versa
        if (Cookies.get("isWholesale") === "true") {
            Cookies.set("isWholesale", "false", { expires: 7 });
            cogoToast.success(
                `You are now in the ${Cookies.get("isWholesale") ? "wholesale" : "retail"} mode`,
                {
                    position: "top-right",
                }
            );
        }else{
            Cookies.set("isWholesale", "true", { expires: 7 });
            cogoToast.success(
                `You are now in the ${Cookies.get("isWholesale") ? "wholesale" : "retail"} mode`,
                {
                    position: "top-right",
                }
            );
        }
    }

    if (stick) {
        return (
            <>
                <button
                    // aria-label="Scroll to top"
                    type="button"
                    className="scroll-top"
                    onClick={onClickHandler}
                >
                    <i className="fa fa-angle-double-up"></i>
                </button>
                <Modal show={landinModalStatus} onHide={() => isLandingOpen(false)}
                    dialogClassName="modal-30vw"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Select Purchase Option
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* provide two option wholesale and reatail */}
                        <div style={{ display: "flex", justifyContent: "space-around", flexDirection: "column", justifyItems: "center", alignItems: "center" }}>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                                <div>
                                    <button
                                        onClick={() => {
                                            switchToWholeSale();
                                            isLandingOpen(false);
                                            Cookies.set("isFirstTime", "false");
                                        }}
                                        style={{
                                            backgroundColor: "#25d366",
                                            color: "white",
                                            padding: "10px 20px 10px 20px",
                                            borderRadius: "5px",
                                            border: "none",
                                            cursor: "pointer",
                                            fontSize: "20px"
                                        }}
                                    >
                                        Select Wholesale
                                    </button>
                                    <div style={{ fontSize: "18px", marginTop: "10px" }}>
                                        <ol>
                                            <li>wholesale price</li>
                                            <li>minimum order of 2000</li>
                                            <li>shipping charges applicable</li>
                                        </ol>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        onClick={() => {
                                            isLandingOpen(false);
                                            Cookies.set("isFirstTime", "false");
                                        }}
                                        style={{
                                            backgroundColor: "#25d366",
                                            color: "white",
                                            padding: "10px 20px 10px 20px",
                                            borderRadius: "5px",
                                            border: "none",
                                            cursor: "pointer",
                                            fontSize: "20px"
                                        }}
                                    >
                                        Select Retail
                                    </button>
                                    <div style={{ fontSize: "18px", marginTop: "10px" }}>
                                        <ol>
                                            <li>retail price</li>
                                            <li>no minimum order</li>
                                            <li>free shipping</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* <a href="https://api.whatsapp.com/send?phone=918320550560&text=Send20%a20%quote"> */}
                <Modal show={modalStatus} onHide={() => isOpen(false)}
                    dialogClassName="modal-30vw"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Select Whatsapp Mode
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="wa-display">
                            <div style={{ display: "flex", gap: "20px", backgroundColor: "white", padding: "10px", borderRadius: "5px", flexDirection: "column", width: "100%" }}>
                                <img src="/customer.png" alt="whatsapp" style={{ width: "260px", height: "200px", alignSelf: "center" }} />
                                <button
                                    onClick={() => {
                                        // window.open("https://api.whatsapp.com/send?phone=917990446858&text=Hello");
                                        window.open("https://api.whatsapp.com/send?phone=917990446858&text=https%3A%2F%2Fwww.qualityzon.com%2F%0A%0AI%20am%20looking%20for%20%3A%0AMy%20Name%20Is%20%3A%0AI%20am%20from%20%3A                                        ");
                                        isOpen(false);
                                    }}
                                    style={{
                                        backgroundColor: "#25d366",
                                        color: "white",
                                        padding: "5px 10px 5px 10px",
                                        borderRadius: "5px",
                                        border: "none",
                                        cursor: "pointer"
                                    }}
                                >
                                    Direct Chat
                                </button>
                            </div>
                            <div style={{ display: "flex", gap: "20px", backgroundColor: "white", padding: "10px", borderRadius: "5px", flexDirection: "column", width: "100%" }}>
                                <img src="/customer.png" alt="whatsapp" style={{ width: "260px", height: "200px", alignSelf: "center" }} />
                                <button
                                    onClick={() => {
                                        window.open("https://api.whatsapp.com/send?phone=919638622905&text=https%3A%2F%2Fwww.qualityzon.com%2F%0A%0AI%20am%20looking%20for%20%3A%0AMy%20Name%20Is%20%3A%0AI%20am%20from%20%3A");
                                        isOpen(false);
                                    }}
                                    style={{
                                        backgroundColor: "#25d366",
                                        color: "white",
                                        padding: "5px 10px 5px 10px",
                                        borderRadius: "5px",
                                        border: "none",
                                        cursor: "pointer"
                                    }}
                                >
                                    Dropshipping
                                </button>
                            </div>
                            <div style={{ display: "flex", gap: "20px", backgroundColor: "white", padding: "10px", borderRadius: "5px", flexDirection: "column", width: "100%" }}>
                                <img src="/customer.png" alt="whatsapp" style={{ width: "260px", height: "200px", alignSelf: "center" }} />
                                <button
                                    onClick={() => {
                                        // window.open("https://api.whatsapp.com/send?phone=918849483653&text=Hello");
                                        window.open("https://api.whatsapp.com/send?phone=918849483653&text=https%3A%2F%2Fwww.qualityzon.com%2F%0A%0AI%20am%20looking%20for%20%3A%0AMy%20Name%20Is%20%3A%0AI%20am%20from%20%3A                                        ");

                                        isOpen(false);
                                    }}
                                    style={{
                                        backgroundColor: "#25d366",
                                        color: "white",
                                        padding: "5px 10px 5px 10px",
                                        borderRadius: "5px",
                                        border: "none",
                                        cursor: "pointer"
                                    }}
                                >
                                    Wholesale
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <button
                    onMouseEnter={() => isOpen(true)}
                    type="button"
                    className="scroll-top-whatsapp"
                >
                    <i className="fa fa-whatsapp"></i>
                </button>
                <button
                    type="button"
                    className="scroll-top-store"
                    onClick={switchToWholeSale}
                >
                    {/* if mode is then the WS if not then RT
                    {
                        Cookies.get("isWholesale") === "true" ? "WS" : "RT"
                    } */}
                    {
                        Cookies.get("isWholesale") === "true" ? "WS" : "RT"
                    }
                </button>
            </>
        );
    }
    return null;
};

export default ScrollToTop;