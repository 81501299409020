import React, { useEffect } from "react";

const EcommerceOrderProduct = (props) => {

  useEffect(() => {
    console.log("EcommerceOrderProduct props", props);
  }, [props]);

  return (
    <React.Fragment>
      <tr>
        <td>
          <div className="d-flex">
            <div className="flex-shrink-0 avatar-md bg-light rounded p-1">
              <img
                src={props?.product?.product?.image[0] || ""}
                alt={props?.product?.name}
                className="img-fluid d-block"
                style={{ width: "6rem" }}
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <h5 className="fs-15">
                <a
                  href="apps-ecommerce-product-details"
                  className="link-primary"
                >
                  {props?.product?.name}
                </a>
              </h5>
            </div>
          </div>
        </td>
        <td>{props?.product?.product?.price}</td>
        <td>{props?.product?.quantity}</td>
        <td>{props?.product?.product?.sku}</td>
        <td>
          {
            props?.product?.product?.category?.map((category, index) => {
              return (
                <span key={index}>{category}</span>
              );
            })
          }
        </td>
        <td className="fw-medium">{
          props?.product?.product?.price * props.product.quantity
        }</td>
      </tr>
    </React.Fragment>
  );
};

export default EcommerceOrderProduct;